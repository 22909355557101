import React from "react";
import { Box, Container } from "@mui/material";

const Section = (props: any) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      style={{
        backgroundImage: props.background,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        zIndex: 10,
      }}
      {...props}
    >
      {props.noContainer && props.children}
      {!props.noContainer && (
        <Container maxWidth="sm">{props.children}</Container>
      )}
    </Box>
  );
};

export default Section;
