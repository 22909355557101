import { getConfig } from "../configs/config";

interface UpdateWorkerPayload {
  workerId: string;
  videoId: string;
}

export function useUpdateWorker() {
  return {
    updateWorker: async (payload: UpdateWorkerPayload) => {
      return fetch(`${getConfig().url}/public/interview/worker/update`, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(payload),
      });
    },
  };
}
