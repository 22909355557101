import { E164Number } from "libphonenumber-js/types";
import { getConfig } from "../configs/config";

interface SendWoltInvitePayload {
  name: string;
  phoneNumber?: E164Number;
}

export function useSendWoltInvite() {
  return {
    sendWoltInvite: async (payload: SendWoltInvitePayload) => {
        const { name, phoneNumber } = payload;

        return fetch(`${getConfig().url}/public/wolt/invite`, {
            headers: { "Content-Type": "application/json; charset=utf-8" },
            method: 'POST',
            body: JSON.stringify({
              name,
              phoneNumber
            })
        })
    },
  };
}
