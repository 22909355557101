import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/Home";
import MyInterviewPage from "./pages/MyInterviewPage";
import WoltFutarPage from "./pages/WoltFutarPager";


export const MainRouter = () => {
    return <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/interview/:workerId" element={<MyInterviewPage />} />
        <Route path="/wolt" element={<WoltFutarPage />} />
      </Routes>
}