import React, { useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  Container,
} from "@mui/material";

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { E164Number } from "libphonenumber-js/types";
import { useSendWoltInvite } from "../../hooks/useSendWoltInvite";
import woltImage from '../../assets/poor.jpeg';


const WoltFutarPage: React.FC = () => {
    
  const [phoneNumber, setPhoneNumber] = useState<E164Number>();
  const [name, setName] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isInvited, setIsInvited] = useState<boolean>(false)

  const { sendWoltInvite } = useSendWoltInvite();

  const handleSendFormData = () => {
    sendWoltInvite({ name, phoneNumber:  `+${phoneNumber}`})
      .then(async response => {
        if (response.status === 200) {
          setIsLoading(false);
          setName('');
          setPhoneNumber(undefined);

          const res = await response.json();

          if (res.message === 'already invited') {
            setIsInvited(true);

            return;
          }

          setIsSuccess(true);
        } else {
          setIsLoading(false);
          setName('');
          setPhoneNumber(undefined);
          setIsError(true);
        }
      })
      .catch(() => {
        setIsLoading(false);
        setName('');
        setPhoneNumber(undefined);
        setIsError(true);
      })
  }

  const ErrorView = () => <>
    <Box mt={3} mb={3}>

      <Typography>Hiba történt a jelentkezés során, kérjük próbáld újra az alábbi gombra kattintva / There was an error while sending the application. Please try again by clicking on the button below.</Typography>

      <Box mt={3}>
        <Button
          variant="contained"
          color="primary"
          style={{ height: '35px', fontSize: '13px'}}
          onClick={() => setIsError(false)}
        >
          újraküld / Retry
        </Button>
      </Box>
    </Box>
  </>


const InvitedView = () => <>
<Box mt={3} mb={3}>

  <Typography>Erre a telefonszámra már lett küldve meghívó / Invite already sent to this phone number.</Typography>

  <Box mt={3}>
    <Button
      variant="contained"
      color="primary"
      style={{ height: '35px', fontSize: '13px'}}
      onClick={() => setIsInvited(false)}
    >
      OK
    </Button>
  </Box>
</Box>
</>

  const SuccessView = () => <>
    <Box mt={3} mb={3}>
      <Typography>Sikeres jelentkezés! / Successful application! </Typography>
      <Box mt={3}>
        <Button
          variant="contained"
          color="primary"
          style={{ height: '35px', fontSize: '13px'}}
          onClick={() => {
            setIsSuccess(false);
            window.location.href = 'https://della.hu/for-students';
          }}
        >
          Kész / Done
        </Button>
      </Box>
    </Box>
  </>

  return (
    <>
    <Container maxWidth={"sm"}>
        <Grid style={{height: '100vh'}} container flexDirection="row" alignItems="center" justifyContent="center">
            <Grid item  xs={12}>
                <Paper>
                    <Box p={3}>
                    {(!isError && !isSuccess && !isInvited) && <>
                        <Box mb={2}>
                            <Typography variant="h4">Jelentkezz Wolt futárnak</Typography>
                        </Box>
                        <Box mb={1}>
                            <Typography >Wolt Partner - Della Student Registration / Wolt Futár - Della Diákmunka Regisztráció</Typography>
                        </Box>
                        <Box mb={3}>
                        <Typography>Please submit your phone number. We will send you the download link via text message. / Kérlek add meg a telefonszámod ahová az alkalmazáshoz szükséges regisztrációs linket küldhetjük.</Typography>
                        </Box>
                        </>}

                        {isError && <ErrorView />}
                        {isSuccess && <SuccessView />}
                        {isInvited && <InvitedView />}
                        {!isError && !isSuccess && !isInvited && <>
                        <Box width="280px" mb={3}>
                        <TextField style={{ width: '100%' }} label="Name / Név" variant="outlined" value={name} onChange={event => setName(event.target.value)} />
                        </Box>
                        <Box mb={3}>
                        <Typography>Phone number / Telefonszám</Typography>
                        <PhoneInput
                            placeholder="Phone number / Telefonszám"
                            country="hu"
                            value={phoneNumber}
                            onChange={setPhoneNumber}/>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="center">
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ height: '35px', fontSize: '13px'}}
                            disabled={!phoneNumber || name === '' || isLoading}
                            onClick={handleSendFormData}
                        >
                            Register/ Jelentkezem
                        </Button>
                    </Box>
                    </>}
                </Box>
                </Paper>
            </Grid>
        </Grid>
       
    </Container>
    <img src={woltImage} alt="wolt" style={{
      width: '100%', 
      height: '100%', 
      position: 'absolute', 
      top: 0, 
      objectFit: 'cover',
      left: 0, 
      zIndex: -1
      }}  />
    </>
  );
};

export default WoltFutarPage;
