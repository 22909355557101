import React from 'react';
import './App.css';
import { MainRouter } from './MainRouter';

function App() {
  return (
   <MainRouter />
  );
}

export default App;
