export const useMobileOperatingSystem = () => {
  if (typeof window !== "undefined") {
    var userAgent =
      window?.navigator?.userAgent ||
      window?.navigator?.vendor ||
      navigator?.userAgent ||
      navigator?.vendor ||
      (window as any).opera;

    if (/android/i.test(userAgent)) {
      return "android";
    }
    //@ts-ignore
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "ios";
    }
  }

  return "unknown";
};
