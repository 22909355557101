
import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import ReactPlayer from "react-player";

import { useMobileOperatingSystem } from "../../hooks/useMobileOperationgSystem";
import { useUpdateWorker } from "../../hooks/useUpdateWorker";
import { getConfig } from "../../configs/config";
import useScript from "../../hooks/useScript";
import Section from "../../components/Section";
import { useParams, useNavigate } from "react-router-dom";
import Axios from "axios";

interface InterviewQuestion {
  question: string;
  partDuration?: number;
  description?: string;
  points?: string[];
  conclusion?: string;
  numOfRetakes?: number;
}

export interface Worker {
        id: string;
        name: string;
        email: string;
        lang: string;
}


export const MyInterviewPage:React.FC = ()  => {
  
    const [videoId, setVideoId] = useState();
    const [hasError, setError] = useState<boolean>(false);
    const { updateWorker } = useUpdateWorker();
    const navigate = useNavigate();
    const device = useMobileOperatingSystem();
    const isMobile = device !== "unknown";

    let { workerId } = useParams();

    const [worker, setWorker] = useState<Worker | null>(null);
    const [questions, setQuestions] = useState<InterviewQuestion[] | null>(null);

    useEffect(() => {
        Axios.get(
            `${getConfig().url}/public/interview/${workerId}`
          ).then((res) => res && res.data ? setWorker(res.data) : {}).catch(() => setError(true));
        
         Axios.get(
        `${getConfig().url}/public/interview/questions`
        ).then((res) => res && res.data ? setQuestions(res.data) : []).catch(() => setError(true));
    }, []);

  useEffect(() => {
    if (worker && videoId) {
      updateWorker({ workerId: worker.id, videoId })
        .then((res): any => {
          if (res.ok) {
            window.alert(
              "Video feltöltése sikeres. A mobil app-ban visszanézheted!"
            );
            window.location.href = isMobile ? "della://" : "della.hu";
            return;
          }
          return window.alert(
            `Hiba történt: ${res.statusText}. Kérlek próbáld újra. `
          );
        })
        .catch((err) => {
          window.alert(`Hiba történt ${err}. Kérlek próbáld újra. `);
        });
    }
  }, [videoId]);


  useEffect(() => {
    window.addEventListener("message", getVideoId, false);
  }, []);

  const getVideoId = (event: any) => {
    if (event.origin !== "https://embed.myinterview.com") return;
    setVideoId(event.data.video);
  };

  useScript({
    url: "https://embed.myinterview.com/widget/2.30.0/widget.js",
    async: true,
    defer: true,
  });

  useScript({
    text: `
      myInterviewConfig = {
        apiKey: "xkqnejs448zhbgzqnuqljpm5",
        debug: false,
        setup: true,
        language: '${worker && worker.lang ? worker.lang : 'en'}',
        user: {
          username: "${worker && worker.name ? worker.name : ''}",
          email: "${worker && worker.email ? worker.email : ''}",
        },
        parts: ${JSON.stringify(questions)}
      };`,
  });

  if(hasError) {
    return <>Invalid request</>
  }  

  if(!worker || !questions) {
    return <>Loading</>
  }  
  
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        textAlign="center"
      >
        <Section color="white" py={20}>
          <Box width="100%" display="flex" justifyContent="center">
            <ReactPlayer
              url="https://della-storage-one.de-fra1.upcloudobjects.com/dellahu/della-video-3.mp4"
              playing={true}
              loop={true}
              controls={false}
              height={250}
              muted={true}
              volume={0}
            />
          </Box>
          <Box mt={5} mb={5}>
            <form>
              <div id="myinterview-widget" style={{ opacity: 0 }}></div>
              <input
                type="hidden"
                name="myinterview-video"
                id="myinterview-videoId"
              />
            </form>
          </Box>
          {isMobile && (
            <Button variant="contained" color="primary" href="della://">
              Vissza az app-ba
            </Button>
          )}
        </Section>
      </Box>
    </>
  );

}

export default MyInterviewPage;